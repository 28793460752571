
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
html,
body {
  margin: 0 auto;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 68.50%;
}
@media screen and (max-width:767px) {
    body{
      font-size: 16px;
    }
    .ant-table{
      font-size: 16px;
    }
}
