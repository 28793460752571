/** @format */

// header nav
.ant-menu-overflow-item.ant-menu-item.logout {
  margin-left: auto;
}
.site-page-header {
  background-color: #333;
  .ant-page-header-heading-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-page-header-heading-title {
    color: #d4d4d4;
  }
  .ant-page-header-heading-sub-title {
    color: #d4d4d4;
  }
}
// mobile Nav
.mobile-view {
  height: 8rem;
  position: relative;
}
.mobile-nav {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 1;
  padding-left: unset;
  margin-bottom: 0;
  justify-content: space-evenly;
  align-items: center;
  background-color: #333;
  box-shadow: 0 -1px 8px rgba(1, 1, 1, 0.3);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;

  a {
    flex: 1;
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    padding: 1rem 0rem;
    &.active {
      color: #333;
      background-color: #f26c4f;
      border-radius: 5px;
      margin: 0 0.5rem;
      border-radius: 0 0 24px 24px;
    }
  }
}

// table
.ant-table-content {
  overflow-x: auto;
  width: 100%;
}

// from
.ant-form-item-label {
  label {
    font-size: 1.6rem;
  }
}
.ant-form-item-control-input {
  font-size: 1.6rem;
  input {
    // height: 4rem;
  }
}

// report
@media (max-width: 767px) {
  .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }
}

// record page
.record-box {
  .ant-card-body {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    form {
      width: 60%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    div.pay-barcode {
      width: 30%;
      display: flex;
      text-align: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        width: 100%;
      }
      .pay-barcord-item {
        img {
          width: 100%;
        }
        span {
          display: inline-block;
        }
      }
    }
  }
}
